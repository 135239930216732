import { defineCustomElement } from "vue";

const filePaths = import.meta.glob("../components/**/*.ce.vue");

const PK_CE = {};

let moduleDefaults = new Map();
let customElementsMap = new Map();

const registerComponents = () => {
  // ^ Check for .ce files -> then register components
  for (const [key, value] of Object.entries(filePaths)) {
    if (value.name.toLowerCase().includes(".ce.vue")) {
      let name = value.name
        .split(/[\\\/]/)
        .pop()
        .toLowerCase(),
        tagName = name.substr(0, name.indexOf("."));

      value().then((module) => {
        moduleDefaults.set(name.substr(0, name.indexOf(".")), module.default);
        customElementsMap.set(
          tagName,
          defineCustomElement(moduleDefaults.get(tagName))
        );
        window.customElements.define(
          "pk-custom-" + tagName,
          customElementsMap.get(tagName)
        );
      });
    }
  }
};

registerComponents();


PK_CE.pkShowCustomModal = async (tagName = "", url = "", params = {}) => {
  var existing = document.getElementsByTagName("pk-custom-" + tagName),
    tag = document.createElement("pk-custom-" + tagName);

  // ^ IF - If element is not mounted yet -> append element to DOM and set attribute
  // ^ ELSE - To Mount element after closing delete html tag and create new element with new data
  if (!existing.length) {
    tag.setAttribute("params", JSON.stringify(params));
    tag.setAttribute("url", url);
    document.body.appendChild(tag);
  } else if (existing && existing[0].hasAttribute("url")) {
    document.body.removeChild(existing[0]);
    tag.setAttribute("params", JSON.stringify(params));
    tag.setAttribute("url", url);
    document.body.appendChild(tag);
  }

  try {
    if (!customElementsMap.has(tagName)) {
      customElementsMap.set(
        tagName,
        defineCustomElement(moduleDefaults.get(tagName))
      );
    }
  } catch (error) {
    console.log("ERROR:", error);
  }

  return;
};

window.PK_CE = PK_CE;
